import React from 'react';

const BannerSecurity = () => (
  <section id="banner" className="style2">
    <div className="inner">
      <header className="major">
        <h1>Security</h1>
      </header>
    </div>
  </section>
);

export default BannerSecurity;
