import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import BannerSecurity from '../components/BannerSecurity';

import forensicsPicture from '../assets/images/security/forensics.jpg';
import biometricEntryPicture from '../assets/images/security/biometricEntry.jpg';
import employeeTrackingPicture from '../assets/images/security/employeeTracking.jpg';
import camerasPicture from '../assets/images/security/cameras.png';
import alarmSystemsPicture from '../assets/images/security/alarmSystems.png';

// TODO: lEARN MORE BUTTON AND SEPARATE PAGE FOR CAMERA SYSTEMS???
const Security = () => (
  <Layout>
    <Helmet>
      <title>Security</title>
      <meta name="description" content="Security is a major concern for businesses. The lack of security, is an even bigger concern. Let our trained professionals help you." />
    </Helmet>

    <BannerSecurity />

    <div id="main">
      <section id="one" className="spotlights">
        <section>
          <img
            src={forensicsPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Computer Forensics</h3>
              </header>
              <p>
Similar to the glamorous espionage world, we offer companies and law enforcement agencies the ability to track what everyone is doing and has done in the past, a digital fingerprint if you will.
                <br />
                <br />
We can also offer real time tracking of everything that is going on within a company, with filters to alert the proper authority figure of a breach in security. For preventative support, we can simply block/ban many websites and programs, creating a more productive atmosphere.

              </p>
            </div>
          </div>
        </section>
        <section>
          <img
            src={biometricEntryPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Biometric Entry</h3>
              </header>
              <p>
Have you ever lost a key, or wished there was an easier way open a door? Biometric entry systems are the new waive of the feature and allow businesses to replace keys and allow employees to use their fingerprints, palm prints, or their iris as a key to gain access to their facilities.
                <br />
                <br />
These systems can help businesses better manage access to sensitive areas and maintain records of anyone that has accessed any of their facilities.

              </p>
            </div>
          </div>
        </section>
        <section>
          <img
            src={camerasPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Cameras</h3>
              </header>
              <p>You can go to costco and pick up a basic camera system for under $200, but how well does it really work? In the event of a disaster, will anything show up on your tape? How long does it really record? We only offer real time solutions, meaning that when you playback your video, it will all be in fluid motion. Our systems can be basic consisting of only fixed cameras, and be very complex, using PTZ cameras that run on fiber.</p>
            </div>
          </div>
        </section>
        <section>
          <img
            src={employeeTrackingPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Employee Tracking</h3>
              </header>
              <p>
Many companies use access cards or pass codes to track who goes to what room, but these are not very accurate as cards can be misplaced and pass codes can be easily replicated. We offer a biometric system in which the only way to access an area is with the use of the individuals finger. This can be used to track when employees come to work, when they leave, and will also restrict access to high security areas, such as the server room, or storage room. Restrictions can be set and customized any way we want, giving our clients full power over their system.
                <br />
                <br />
We also provide RFID tracking for security conscious companies who need real time tracking capabilites for any equipment or employees throughout their facilites

              </p>
            </div>
          </div>
        </section>
        <section>
          <img
            src={alarmSystemsPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Alarm Systems</h3>
              </header>
              <p>
Many businesses rely on alarm systems to alert them of break ins, unauthorized access, and other disasters. With our strong background in security, we are able to integrate your alarm system, with our other security camera, biometric, and RFID systems, providing you a complete security solution.
                <br />
                <br />
Our unified approach to your security solution will help you and your business better secure your employees and your facilities.

              </p>
            </div>
          </div>
        </section>
      </section>
    </div>

  </Layout>
);

export default Security;
